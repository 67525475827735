import { WALLET } from 'hooks/management.hook'
import React, { createContext, useContext, useMemo } from 'react'
import { useUser } from './UserContext'
import { find } from 'lodash'
import { roundDownDecimal } from 'helper/common'
const WalletContext = createContext(null)

export function WalletProvider({ children }) {
  const { user } = useUser()

  const pointBalance = useMemo(() => {
    return roundDownDecimal(find(user?.wallets, { walletType: WALLET.POINT })?.balance || 0)
  }, [user])
  const bonusBetBalance = useMemo(() => {
    return roundDownDecimal(find(user?.wallets, { walletType: WALLET.BONUS_BET })?.balance || 0)
  }, [user])
  const promotionBalance = useMemo(() => {
    return roundDownDecimal(find(user?.wallets, { walletType: WALLET.PROMOTION_WALLET })?.balance || 0)
  }, [user])
  const pitBalance = useMemo(() => {
    return roundDownDecimal(find(user?.wallets, { walletType: WALLET.PIT_WALLET })?.balance || 0)
  }, [user])
  const bonusSaleBalance = useMemo(() => {
    return roundDownDecimal(find(user?.wallets, { walletType: WALLET.BONUS_SALE })?.balance || 0)
  }, [user])
  const expertLeadBalance = useMemo(() => {
    return roundDownDecimal(find(user?.wallets, { walletType: WALLET.EXPERT_LEAD_WALLET })?.balance || 0)
  }, [user])
  const expertFundBalance = useMemo(() => {
    return roundDownDecimal(find(user?.wallets, { walletType: WALLET.EXPERT_FUND_WALLET })?.balance || 0)
  }, [user])
  const userPlayBalance = useMemo(() => {
    return roundDownDecimal(find(user?.wallets, { walletType: WALLET.USER_PLAY_WALLET })?.balance || 0)
  }, [user])

  const pointWallet = useMemo(() => {
    const result = find(user?.wallets, { walletType: WALLET.POINT })
    if (result) {
      return {
        ...result,
        balance: roundDownDecimal(result?.balance || 0)
      }
    }
    return {}
  }, [user])
  const bonusBetWallet = useMemo(() => {
    const result = find(user?.wallets, { walletType: WALLET.BONUS_BET })
    if (result) {
      return {
        ...result,
        balance: roundDownDecimal(result?.balance || 0)
      }
    }
    return {}
  }, [user])
  const promotionWallet = useMemo(() => {
    const result = find(user?.wallets, { walletType: WALLET.PROMOTION_WALLET })
    if (result) {
      return {
        ...result,
        balance: roundDownDecimal(result?.balance || 0)
      }
    }
    return {}
  }, [user])
  const pitWallet = useMemo(() => {
    const result = find(user?.wallets, { walletType: WALLET.PIT_WALLET })
    if (result) {
      return {
        ...result,
        balance: roundDownDecimal(result?.balance || 0)
      }
    }
    return {}
  }, [user])
  const bonusSaleWallet = useMemo(() => {
    const result = find(user?.wallets, { walletType: WALLET.BONUS_SALE })
    if (result) {
      return {
        ...result,
        balance: roundDownDecimal(result?.balance || 0)
      }
    }
    return {}
  }, [user])
  const expertLeadWallet = useMemo(() => {
    const result = find(user?.wallets, { walletType: WALLET.EXPERT_LEAD_WALLET })
    if (result) {
      return {
        ...result,
        balance: roundDownDecimal(result?.balance || 0)
      }
    }
    return {}
  }, [user])
  const expertFundWallet = useMemo(() => {
    const result = find(user?.wallets, { walletType: WALLET.EXPERT_FUND_WALLET })
    if (result) {
      return {
        ...result,
        balance: roundDownDecimal(result?.balance || 0)
      }
    }
    return {}
  }, [user])
  const userPlayWallet = useMemo(() => {
    const result = find(user?.wallets, { walletType: WALLET.USER_PLAY_WALLET })
    if (result) {
      return {
        ...result,
        balance: roundDownDecimal(result?.balance || 0)
      }
    }
    return {}
  }, [user])

  return (
    <WalletContext.Provider
      value={{
        wallet: {
          pointBalance,
          bonusSaleWallet,
          bonusBetWallet,
          bonusBetBalance,
          expertFundBalance,
          expertLeadBalance,
          bonusSaleBalance,
          userPlayBalance,
          pointWallet,
          expertLeadWallet,
          expertFundWallet,
          userPlayWallet,
          promotionBalance,
          promotionWallet,
          pitBalance,
          pitWallet,
        }
      }}>
      {children}
    </WalletContext.Provider>
  )
}

export function useWallet() {
  return useContext(WalletContext)
}

export const DEPOSIT_TRX_UNIT = {
  VND: 'VND',
  USDT: 'USDT',
  PIT: 'PIT'
}

export const WITHDRAW_TRX_UNIT = {
  VND: 'VND',
  USDT: 'USDT',
  PIT: 'PIT'
}
