const handleSetListCategory = (data) => ({
  type: "GAME_CATEGORY",
  data: data
})
const handleSetListCategoryMiniGame = (data) => ({
  type: "GAME_CATEGORY_MINI_GAME",
  data: data
})
const handleSetListGame = (data) => ({
  type: "GAME_INFO",
  data: data
})

const handleSetGameCategoryActive = (data) => ({
  type: "GAME_CATEGORY_ACTIVE",
  data: data
})
const setIsWebviewApp = (data) => ({
  type: "IS_WEBVIEW_APP",
  data: data
})

const setGameHotLayout = (data) => ({
  type: "GAME_HOT_LAYOUT",
  data: data
})
const setListGameHotInfo = (data) => ({
  type: "GAME_HOT_INFO",
  data: data
})

export { handleSetListCategory, handleSetListCategoryMiniGame, handleSetListGame, handleSetGameCategoryActive, setIsWebviewApp, setGameHotLayout, setListGameHotInfo }
