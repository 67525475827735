import { reject } from 'lodash'
import Request from './request'
import { API_GAME_URL } from 'constants/url'

export default class GameService {
  static async GameCategoryFind(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'GameInfo/user/getListGameCategory',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }
  static async GameInfoFind(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'GameInfo/user/getList',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }
  
  static async checkResultDummyRecord(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'GamePlayRecords/test/checkResultDummyRecord',
        data
      }).then((result = {}) => {
        resolve(result)
      }).catch(err => reject(err))
    })
  }

  static async placeDummyRecord(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'GamePlayRecords/test/placeDummyRecord',
        data
      }).then((result = {}) => {
        resolve(result)
      }).catch(err => reject(err))
    })
  }
  
  static async GamePlayrecordGetList(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'GamePlayRecords/user/getListPlayRecordDistinctByGame',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }

  static async findReferralUserPlayByGame(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'GamePlayRecords/user/findReferralUserPlayByGame',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }

  static async gameInfoUserLaunchGame(url = "") {
    return new Promise((resolve) => {
      Request.send({
        method: 'GET',
        path: url,
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }
  static async getGameLaunchUrl(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'GameInfo/user/getGameLaunchUrl',
        data,
        newUrl: API_GAME_URL
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }

  static async gameHotInfoGetList(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'GameHotInfo/user/getList',
        data,
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }
  static async gameHotConfigFind(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'GameHotConfig/user/find',
        data,
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }
  static async getInfoGameLaunch(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'GameInfo/user/getInfoGameLaunch',
        data,
        newUrl: API_GAME_URL
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }
}
