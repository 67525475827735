import { routes } from "App"
import BasicAlertPopup from "components/Basic/BasicAlertPopup/BasicAlertPopup"
import { LAUNCH_GAME_ERROR } from "constants/error"
import { useUser } from "context/UserContext"
import useCommonHook from "hooks/useCommonHook"
import { useModalLoginHooks } from "Page/LoginByModal"
import { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import GameService from "services/gameService"
import { isMobile, isAndroid, isIOS } from 'react-device-detect';

export default function useActionsDirectTapToCoin() {
    const history = useHistory()
    const { translate } = useCommonHook()
    const { user } = useUser()
    const { setIsOpenModalLogin } = useModalLoginHooks()
    const [loading, setLoading] = useState(false)
    const isWebviewApp = useSelector((state) => (state?.gameInfo?.isWebviewApp))

    const listCategoryMiniGame = useSelector((state) => (state?.gameInfo?.listCategoryMiniGame))
    const listGame = useSelector((state) => (state?.gameInfo?.listGame))
    const listGameFloatButton = listGame.filter(item =>
        listCategoryMiniGame.some(filterItem => filterItem.gameCategoryId === item.gameInfoCategory)
    )

    const isOpenIframe = useMemo(() => {
        if (isIOS) {
            return true
        }
        else if (isWebviewApp) {
            return isAndroid ? false : true;
        }
        else {
            return false
        }
    }, [isWebviewApp])

    const handleDirectLinkGames = (item) => {
        if (!item?.gameStatus) {
            BasicAlertPopup({
                title: translate(`Maintain`),
                content: `
             <div>${item?.gameInfoNote || translate('please_return_after_time')}</div>
          `,
                confirmButtonText: translate('Confirm'),
                confirmButtonColor: "primary",
                color: 'primary',
                icon: 'maintenance',
                onConfirm: () => { },
            })
            return
        }
        if (user?.token || user?.appUserId) {
            setLoading(true)
            GameService.getGameLaunchUrl({
                gameInfoId: item?.gameInfoId,
                isMobile: isMobile ? 1 : 0,
                confirm: 1
            }).then(result => {
                setLoading(false)
                const { isSuccess, data, error } = result

                if (isSuccess) {
                    const gameUrl = data?.gameDirectLink
                    if ((gameUrl?.startsWith('https://') || gameUrl?.startsWith('http://')) && !gameUrl?.startsWith(window.location.origin)) {
                        if (isOpenIframe) {
                            history.replace({ pathname: routes.launchGame.path, state: { url: `${gameUrl}${isWebviewApp ? "&isWebviewApp=1" : ""}` } })
                        } else {
                            window.open(gameUrl, '_blank')
                        }
                    } else {
                        BasicAlertPopup({
                            title: translate('Fail'),
                            content: translate('Failed_to_enter_the_game'),
                            icon: "danger",
                            showCloseButton: true,
                        })
                    }
                }
                else {
                    handleError(error)
                }
            })
        }
        else {
            setIsOpenModalLogin(true)
        }
    }

    const handleError = (error) => {
        if (LAUNCH_GAME_ERROR?.[error]) {
            BasicAlertPopup({
                title: translate('Fail'),
                content: translate(LAUNCH_GAME_ERROR?.[error]),
                icon: "danger",
                showCloseButton: true,
            })
        } else {
            BasicAlertPopup({
                title: translate('Fail'),
                content: translate('Failed_to_enter_the_game'),
                icon: "danger",
                showCloseButton: true,
            })
        }
    }

    return {
        loading,
        listGameFloatButton,
        handleDirectLinkGames
    }
}