export const errorRequestDepositWithdraw = {
  NOT_AUTHORIZED: 'NOT_AUTHORIZED',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  DUPLICATE_TRANSACTION_ID: 'DUPLICATE_TRANSACTION_ID',
  USDT_WITHDRAW_LOCKED: 'USDT_WITHDRAW_LOCKED',
  BANK_WITHDRAW_LOCKED: 'BANK_WITHDRAW_LOCKED',
  PIT_WITHDRAW_LOCKED: 'PIT_WITHDRAW_LOCKED',
  ALL_WITHDRAW_LOCKED: 'ALL_WITHDRAW_LOCKED',
  DEPOSIT_LIMIT_ERROR: 'DEPOSIT_LIMIT_ERROR',
  MAINTAIN_DEPOSIT: "MAINTAIN_DEPOSIT",
  MAINTAIN_ALL: "MAINTAIN_DEPOSIT",
  LIMIT_MIN_OR_MAX_DEPOSIT: "DEPOSIT_LIMIT_ERROR",
  LIMIT_MIN_OR_MAX_WITHDRAW: "LIMIT_MIN_OR_MAX_WITHDRAW",
  NOT_ENOUGH_TIME: 'NOT_ENOUGH_TIME',
  LIMIT_MAX_WITHDRAW: 'LIMIT_MIN_OR_MAX_WITHDRAW',
  INVALID_WITHDRAW_DATA: 'INVALID_WITHDRAW_DATA', //Dữ liệu rút tiền bị sai (thiếu cái gì đó)
  ALREADY_PROCESS_TRANSACTION: 'ALREADY_PROCESS_TRANSACTION', //Giao dịch đã được xử lý
  INVALID_USER_WALLET: 'INVALID_USER_WALLET', //Không tìm thấy ví để xử lý tiền
  PAYMENT_METHOD_IS_NOT_READY: 'PAYMENT_METHOD_IS_NOT_READY', //Không tìm thấy ví để xử lý tiền
  PAYMENT_GATEWAY_NOT_READY: 'PAYMENT_GATEWAY_NOT_READY',
}

export const WITHDRAW_FEE_ERROR = {
  WITHDRAW_FEE_MAX_COUNT: "WITHDRAW_FEE_MAX_COUNT",
  WITHDRAW_FEE_VALID_BET: "WITHDRAW_FEE_VALID_BET",
  WITHDRAW_FEE_DEPOSIT_ENOUGH: "WITHDRAW_FEE_DEPOSIT_ENOUGH",
  WITHDRAW_FEE_DEPOSIT_THAN_PLAY: "WITHDRAW_FEE_DEPOSIT_THAN_PLAY",
  WITHDRAW_FEE_LAST_DEPOSIT_THAN_LAST_PLAY: "WITHDRAW_FEE_LAST_DEPOSIT_THAN_LAST_PLAY",
}

export const LAUNCH_GAME_ERROR = {
  UNAUTHORIZE_LAUNCH_GAME: 'UNAUTHORIZE_LAUNCH_GAME', //Tài khoản chưa được phép chơi
  INVALID_GAME: 'INVALID_GAME', //Sai thông tin trò chơi
  GAME_IS_NOT_READY: 'GAME_IS_NOT_READY', //Trò chơi chưa sẵn sàng
  GAME_IS_MAINTENANCE: 'GAME_IS_MAINTENANCE', //Trò chơi đang bảo trì
  DEPOSIT_GAME_ERROR: 'DEPOSIT_GAME_ERROR', //Trò chơi đang bảo trì
}

export const USER_ERROR = {
  DUPLICATED_USER: 'DUPLICATED_USER', //Trùng tài khoản
  DUPLICATED_USER_EMAIL: 'DUPLICATED_USER_EMAIL', //Trùng email
  DUPLICATED_USER_PHONE: 'DUPLICATED_USER_PHONE', //Trùng số điện thoại
  DUPLICATED_USER_FIRSTNAME: 'DUPLICATED_USER_FIRSTNAME', //Trùng biệt danh
  INVALID_REFER_USER: 'INVALID_REFER_USER', //Mã giới thiệu không đúng
  NOT_AUTHORIZED: 'NOT_AUTHORIZED', //Không có quyền
  USER_LOCKED: 'USER_LOCKED', //Tài khoản bị khóa
  NOT_VERIFIED_EMAIL: 'NOT_VERIFIED_EMAIL', //Chưa xác thực email
  NOT_VERIFIED_PHONE: 'NOT_VERIFIED_PHONE', //Chưa xác thực số điện thoại
  REFER_USER_NOT_FOUND: 'REFER_USER_NOT_FOUND', //Mã giới thiệu không đúng
  OTP_NOT_FOUND: 'OTP_NOT_FOUND', //OTP không đúng
  NOT_UPGRADED: 'NOT_UPGRADED', //Chưa nâng cấp tài khoản
  WITHDRAW_AND_FEE: 'WITHDRAW_AND_FEE', //Rút tiền mất phí
  BALANCE_NOT_ENOUGH: 'BALANCE_NOT_ENOUGH', //Số dư tài khoản không đủ
  WALLET_NOT_FOUND: 'WALLET_NOT_FOUND', //Không tìm thấy ví
  NOT_ALLOWED_DEPOSIT: 'NOT_ALLOWED_DEPOSIT', //Không được phép nạp tiền
  NOT_ALLOWED_WITHDRAW: 'NOT_ALLOWED_WITHDRAW', //Không được phép rút tiền
  BLOCKED_WITHDRAW: 'BLOCKED_WITHDRAW', //Khóa rút tiền
  USER_BLOCKED_WITHDRAW_BANK: 'USER_BLOCKED_WITHDRAW_BANK', //Khóa rút tiền về ngân hàng
  USER_BLOCKED_WITHDRAW_CRYPTO: 'USER_BLOCKED_WITHDRAW_CRYPTO', //KHóa rút tiền về ví điện tử
  LOGIN_FAIL: 'LOGIN_FAIL', //Đăng nhập thất bại
  USER_BLOCKED: 'USER_BLOCKED', //khóa tài khoản login sai quá 5 lần
  USER_NOT_FOUND: 'USER_NOT_FOUND', //Không tìm thấy thông tin người dùng
  FORGOT_PASSWORD_FAIL: 'FORGOT_PASSWORD_FAIL', //Quên mật khẩu thất bại
  MAX_LIMITED_RESET_PASSWORD: 'MAX_LIMITED_RESET_PASSWORD', //Quá số lần cho phép quên mật khẩu
  MAX_LIMITED_RESET_SECONDARY_PASSWORD: 'MAX_LIMITED_RESET_SECONDARY_PASSWORD', //Quá số lần cho phép quên mật khẩu giao dịch
  USER_UPDATE_FAILED: 'USER_UPDATE_FAILED',
  SEARCH_DATE_LIMIT: 'SEARCH_DATE_LIMIT',
  WRONG_SECONDARY_PASSWORD: 'WRONG_SECONDARY_PASSWORD',
  BLOCKED_REGISTER_MEMBER: 'BLOCKED_REGISTER_MEMBER', // Đại lý bị hạn chế đăng ký hội viên
  IP_BLOCKED: 'IP_BLOCKED',
  INVALID_TOKEN: "INVALID_TOKEN"
}