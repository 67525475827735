import ForgotPass from 'Page/ForgotPass'
import PaymentMethod from 'Page/PaymentMethod'
import PaymentMethodInsert from 'Page/PaymentMethodInsert'
import React, { lazy, Suspense, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Layout from './../src/Page/Layout'
import { THEME } from 'constants/url'
import { Splash } from 'Page/Splash'
import Loading from 'components/Loading'
const SearchProfessional = lazy(() => import('./../src/Page/CopyBet/ProfessionalList/SearchProfessional'))
const ProfessionalDettail = lazy(() => import('./../src/Page/CopyBet/ProfessionalList/ProfessionalDettail'))
const BetOrderDetail = lazy(() => import('./../src/Page/CopyBet/ProfessionalList/BetOrderDetail'))
const CopyProfessional = lazy(() => import('./../src/Page/CopyBet/ProfessionalList/CopyProfessional'))
const CopyBet = lazy(() => import('./../src/Page/CopyBet/ProfessionalList'))
const Professional = lazy(() => import('./../src/Page/CopyBet/Professional'))
const RegisterProfessionalForm = lazy(() => import('./../src/Page/CopyBet/Professional/RegisterProfessionalForm'))
const EditProfessionalForm = lazy(() => import('./../src/Page/CopyBet/Professional/EditProfessionalForm'))
const DepositAmountProfessional = lazy(() => import('./../src/Page/CopyBet/Professional/DepositAmountProfessional'))
const ComissionDetail = lazy(() => import('./../src/Page/CopyBet/Professional/ComissionDetail'))
const WithDrawAmountProfessional = lazy(() => import('./../src/Page/CopyBet/Professional/WithDrawAmountProfessional'))
const CancelProfessional = lazy(() => import('./../src/Page/CopyBet/Professional/CancelProfessional'))
const NotificationDetail = lazy(() => import('./../src/Page/Notifications/Detail'))
const EventDetail = lazy(() => import('./../src/Page/Notifications/eventDetail'))
const InstructDetail = lazy(() => import('./../src/Page/Instruct/detail'))
const Home = lazy(() => import('./../src/Page/Home'))
const Register = lazy(() => import('./../src/Page/Register'))
const NationRegister = lazy(() => import('./../src/Page/Register/NationRegister'))
const Login = lazy(() => import('./Page/Login/index'))
const SettingLanguage = lazy(() => import('./Page/SettingLanguage'))
const Notifications = lazy(() => import('./../src/Page/Notifications'))
const Instruct = lazy(() => import('./../src/Page/Instruct/index'))
const Profile = lazy(() => import('./../src/Page/Profile/profile'))
const AccountInfo = lazy(() => import('./Page/AccountInfo'))
const AutoLogout = lazy(() => import('./Page/AutoLogout'))
const DownloadApp = lazy(() => import('./Page/DownloadApp'))
const DepositAuto = lazy(() => import('./Page/DepositAuto'))
const WithdrawAuto = lazy(() => import('./Page/WithdrawAuto'))
const Endow = lazy(() => import('./Page/Endow'))
const Introduce = lazy(() => import('./Page/Introduce'))
const Two2Fa = lazy(() => import('./Page/Login/two2FA'))
const Two2FaVerify = lazy(() => import('./Page/Two2Fa/two2Fa'))
const HistoryDepositWithdraw = lazy(() => import('./Page/HistoryDepositWithdraw'))
const Agency = lazy(() => import('./Page/Agency'))
const HistoryScore = lazy(() => import('./Page/HistoryScore'))
const HistoryTrade = lazy(() => import('./Page/HistoryTrade'))
const PersonalRefund = lazy(() => import('./Page/PersonalRefund'))
const Insurance = lazy(() => import('./Page/Insurance'))
const ChangePassword = lazy(() => import('./Page/changePassword'))
const TestTrade = lazy(() => import('./Page/TestTrade'))
const QrCode = lazy(() => import('./Page/QrCode'))
const Iframe = lazy(() => import('./Page/Iframe'))
const MyWallet = lazy(() => import('./Page/MyWallet'))
const PointWallet = lazy(() => import('./Page/MyWallet/PointWallet'))
const WalletDetail = lazy(() => import('./Page/MyWallet/WalletDetail'))
const DetailPointWallet = lazy(() => import('./Page/MyWallet/PointWallet/detail'))
const DetailPromotionWallet = lazy(() => import('./Page/MyWallet/WalletDetail/detail'))
const DetailDraw = lazy(() => import('./Page/MyWallet/PointWallet/DetailDraw'))
const TransferCommission = lazy(() => import('./Page/TransferCommission'))
const TransferWallet = lazy(() => import('./Page/TransferWallet'))
const ManualDeposit = lazy(() => import('./Page/ManualDeposit'))
const ManualDepositUSDT = lazy(() => import('./Page/ManualDeposit/manualDepositUSDT'))
const GameUrl = lazy(() => import('./Page/GameUrl'))
const LaunchGame = lazy(() => import('./Page/LaunchGame'))
const GameLobby = lazy(() => import('./Page/GameLobby'))
const AutoLogin = lazy(() => import('./Page/AutoLogin'))

export const routes = {
  nation: {
    path: '/register-nation',
    component: NationRegister,
    isAuth: false,
    label: 'Chọn quốc gia'
  },
  login: {
    path: '/login',
    component: Login,
    isAuth: false,
    label: 'Đăng nhập'
  },
  register: {
    path: '/register',
    component: Register,
    isAuth: false,
    label: 'Đăng ký'
  },
  forgotPass: {
    path: '/forgot',
    component: ForgotPass,
    isAuth: false,
    label: 'Quên mật khẩu'
  },
  settingLanguage: {
    path: '/setting-language',
    component: SettingLanguage,
    isAuth: false,
    label: 'Dịch'
  },
  introduce: {
    path: '/introduce',
    component: Introduce,
    isAuth: false,
    label: 'Giới thiệu',
  },
  accountInfo: {
    path: '/profile/account',
    component: AccountInfo,
    isAuth: true,
    label: 'Thông tin tài khoản'
  },
  myWallet: {
    path: '/my-wallet',
    component: MyWallet,
    isAuth: true,
    label: 'Ví của tôi'
  },
  manualDeposit: {
    path: '/Manual-deposit',
    component: ManualDeposit,
    isAuth: true,
    label: 'Nạp thủ công'
  },
  manualDepositUSDT: {
    path: '/Manual-deposit-usdt',
    component: ManualDepositUSDT,
    isAuth: true,
    label: 'Nạp thủ công USDT'
  },
  PointWallet: {
    path: "/PointWallet",
    component: PointWallet,
    isAuth: true,
    label: 'Ví chính'
  },
  PointWalletbyId: {
    path: "/PointWallet/:id",
    component: DetailPointWallet,
    isAuth: true,
    isHidden: true
  },
  WalletDetail: {
    path: "/WalletDetail/:walletType",
    component: WalletDetail,
    isAuth: true,
    label: 'Ví ưu đãi'
  },
  DetailPromotionWallet: {
    path: "/WalletRecord/:id",
    component: DetailPromotionWallet,
    isAuth: true,
    isHidden: true
  },
  PointWalletByIdDraw: {
    path: "/PointWalletDraw/:id",
    component: DetailDraw,
    isAuth: true,
    isHidden: true
  },
  transferCommission: {
    path: "/transferCommission",
    component: TransferCommission,
    isAuth: true,
    isHidden: true
  },
  transferWallet: {
    path: "/transferWallet/:wallet",
    component: TransferWallet,
    isAuth: true,
    isHidden: true
  },
  notificationPersional: {
    path: '/management/notificationPersional',
    component: Notifications,
    isAuth: true,
    label: <FormattedMessage id="notification" />
  },
  notificationDetail: {
    path: '/management/notificationPersional/:id',
    component: NotificationDetail,
    isAuth: true,
    label: <FormattedMessage id="notification" />
  },
  notificationSystemDetail: {
    path: '/management/notificationSystem/:id',
    component: NotificationDetail,
    isAuth: false,
    label: <FormattedMessage id="notification" />
  },
  notificationSystem: {
    path: '/management/notificationSystem',
    component: Notifications,
    isAuth: true,
    isHidden: true,
    label: <FormattedMessage id="notification" />
  },
  eventDetail: {
    path: '/management/event/:id',
    component: EventDetail,
    isAuth: true,
    isHidden: true,
    label: <FormattedMessage id="notification" />
  },
  managementProfile: {
    path: '/management/profile',
    component: Profile,
    isAuth: true,
    label: 'Tài khoản',
    showFooterMobile: true
  },
  paymentMethod: {
    path: '/payment-method',
    component: PaymentMethod,
    isAuth: true
  },
  paymentMethodInsert: {
    path: '/payment-method-insert/:type',
    component: PaymentMethodInsert,
    isAuth: true
  },
  instruct: {
    path: '/instruct/:id',
    isAuth: true,
    component: Instruct
  },
  instructDetail: {
    path: '/instruct/detail/:id',
    component: InstructDetail,
    isAuth: true,
    isHidden: true,
    label: <FormattedMessage id="Guide" />
  },
  autoLogout: {
    path: '/autoLogout',
    component: AutoLogout,
    isAuth: true,
    isHidden: true
  },
  downloadApp: {
    path: '/downloadApp',
    component: DownloadApp,
    isAuth: true,
    isHidden: true
  },
  copyBet: {
    path: '/copyBet',
    component: CopyBet,
    isAuth: true,
    isHidden: true,
  },
  searchProfessional: {
    path: '/searchProfessional',
    component: SearchProfessional,
    isAuth: true,
    isHidden: true,
  },
  professionalDetail: {
    path: '/professionalDetail/:id',
    component: ProfessionalDettail,
    isAuth: true,
    isHidden: true,
  },
  Professional: {
    path: '/professional',
    component: Professional,
    isAuth: true,
    isHidden: true,
  },
  registerProfessionalForm: {
    path: '/registerProfessional',
    component: RegisterProfessionalForm,
    isAuth: true,
    isHidden: true,
  },
  editProfessional: {
    path: '/editProfessional',
    component: EditProfessionalForm,
    isAuth: true,
    isHidden: true,
  },
  depositProfessional: {
    path: '/depositProfessional',
    component: DepositAmountProfessional,
    isAuth: true,
    isHidden: true,
  },
  comissionDetail: {
    path: '/commissionDetail',
    component: ComissionDetail,
    isAuth: true,
    isHidden: true,
  },
  withDrawAmountProfessional: {
    path: '/withdrawProfessional',
    component: WithDrawAmountProfessional,
    isAuth: true,
    isHidden: true,
  },
  cancelProfessional: {
    path: '/cancelProfessional',
    component: CancelProfessional,
    isAuth: true,
    isHidden: true,
  },
  betOrderDetail: {
    path: '/betOrderDetail/:id/:gameCategory/:gameInfoId/:date',
    component: BetOrderDetail,
    isAuth: true,
    isHidden: true,
  },
  CopyProfessional: {
    path: '/copyProfessional/:id',
    component: CopyProfessional,
    isAuth: true,
    isHidden: true,
  },
  depositAuto: {
    path: '/depositAuto',
    component: DepositAuto,
    isAuth: true,
    isHidden: true,
  },
  withdrawAuto: {
    path: '/withdrawAuto',
    component: WithdrawAuto,
    isAuth: true,
    isHidden: true,
  },
  endow: {
    path: '/endow',
    component: Endow,
    isAuth: true,
    isHidden: true,
  },
  two2Fa: {
    path: '/two2fa',
    isAuth: false,
    component: Two2Fa
  },
  two2FaVerify: {
    path: '/two2FaVerify',
    isAuth: true,
    component: Two2FaVerify
  },
  historyDepositWithdraw: {
    path: '/historyDepositWithdraw',
    isAuth: true,
    component: HistoryDepositWithdraw
  },
  agency: {
    path: '/agency',
    isAuth: true,
    component: Agency,
  },
  historyScore: {
    path: '/historyScore',
    isAuth: true,
    component: HistoryScore
  },
  historyTrade: {
    path: '/historyTrade',
    isAuth: true,
    component: HistoryTrade,
  },
  personalRefund: {
    path: '/personalRefund',
    isAuth: true,
    component: PersonalRefund,
  },
  insurance: {
    path: '/insurance',
    isAuth: true,
    component: Insurance,
    showFooterMobile: true
  },
  changePassword: {
    path: '/changePassword',
    isAuth: true,
    component: ChangePassword,
  },
  insuranceBuy: {
    path: '/insuranceBuy',
    isAuth: true,
    component: Insurance,
    showFooterMobile: true
  },
  insuranceHistory: {
    path: '/insuranceHistory',
    isAuth: true,
    component: Insurance,
    showFooterMobile: true
  },
  insuranceRefund: {
    path: '/insuranceRefund',
    isAuth: true,
    component: Insurance,
    showFooterMobile: true
  },
  insuranceCommissionPolicy: {
    path: '/insuranceCommission/policy',
    isAuth: true,
    component: Insurance,
    showFooterMobile: true
  },
  insuranceCommission: {
    path: '/insuranceCommission',
    isAuth: true,
    component: Insurance,
    showFooterMobile: true
  },
  qrCode: {
    path: '/qrCode',
    isAuth: false,
    component: QrCode,
    showFooterMobile: false
  },
  testTrade: {
    path: '/test-lenh',
    isAuth: true,
    component: TestTrade,
    outerComponent: true,
  },
  iframe: {
    path: '/iframe',
    isAuth: false,
    component: Iframe,
  },
  gameUrl: {
    path: '/gameUrl/:id',
    isAuth: false,
    component: GameUrl,
  },
  launchGame: {
    path: '/launchGame',
    isAuth: false,
    component: LaunchGame,
    outerComponent: true,
  },
  gameLobby: {
    path: '/gameLobby/:gameProviderId/:gameInfoCategory',
    isAuth: true,
    component: GameLobby,
  },
  autoLogin: {
    path: '/autoLogin',
    isAuth: false,
    component: AutoLogin,
  },
}
routes.home = {
  path: '/',
  component: Home,
  isAuth: false,
  label: 'Trang chủ',
  showFooterMobile: true
}

function App() {
  const isUserLoggedIn = useSelector((state) => (state.member ? state.member.isUserLoggedIn : false))
  const isLoadingResource = useSelector((state) => (state.loadingResource.isLoadingResource))

  useEffect(() => {
    document.body.setAttribute('data-theme', THEME)
  }, [])

  return isLoadingResource ? (
    <Splash />
  ) : (
    <Router>
      <Suspense
        fallback={
          <div>
            <Loading />
          </div>
        }>
        <Switch>
          {Object.keys(routes).map((key, index) => {
            if (isUserLoggedIn && routes[key].isAuth) {
              return (
                <Route
                  key={index}
                  exact
                  path={routes[key].path}
                  component={(props) => (
                    <Layout
                      {...props}
                      isAuth={routes[key].isAuth}
                      Component={routes[key].component}
                      showFooterMobile={routes[key].showFooterMobile}
                      outerComponent={routes[key].outerComponent}
                      className={`${routes[key].className || 'HOME'}`}
                    />
                  )}
                />
              )
            } else if (!routes[key].isAuth) {
              return (
                <Route
                  key={index}
                  exact
                  path={routes[key].path}
                  component={(props) => (
                    <Layout
                      {...props}
                      isAuth={routes[key].isAuth}
                      Component={routes[key].component}
                      showFooterMobile={routes[key].showFooterMobile}
                      outerComponent={routes[key].outerComponent}
                      className={routes[key].isHeader ? 'HOME' : 'LOGIN'}
                    />
                  )}
                />
              )
            }
          })}
        </Switch>
      </Suspense>
    </Router>
  )
}

export default App
